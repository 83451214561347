import { Button, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { ACCProduct, ACCService } from 'src/@types/cart';
import { useFormatCurrency } from 'src/hooks/useFormatCurrency';
import { getCartState } from 'src/redux/slices/cart';

import Scrollbar from '../Scrollbar';
import { CartItem } from './CartItem';

export enum CartTransactionEnum {
  EXPENSE = 'expense',
  SALE = 'sale',
}

type Props = {
  transactionType: CartTransactionEnum;
  height?: number;
  switchHeight?: VoidFunction;
  currentHeightEqualsHeight?: boolean;
  disablePriceField?: boolean;
};

export default function CartTable({
  transactionType,
  height = 295,
  switchHeight,
  currentHeightEqualsHeight,
  disablePriceField,
}: Props) {
  const { formatCurrency } = useFormatCurrency();
  const { products, services, amountProducts, amountServices } =
    useSelector(getCartState);
  const total = amountProducts + amountServices;

  return (
    <>
      <Scrollbar
        sx={{
          height,
          backgroundColor: '#fff',
          border: '1px solid #dedede',
          borderRadius: 1,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          padding: 1,
        }}
      >
        {Object.keys(services).map((key, idx) => {
          const service = services[parseInt(key)] as ACCService;

          return (
            <CartItem
              key={service.id}
              transactionType={transactionType}
              data={service}
              itemType="services"
              idx={idx}
            />
          );
        })}
        {Object.keys(products).map((key, idx) => {
          const product = products[parseInt(key)] as ACCProduct;

          return (
            <CartItem
              key={product.id}
              transactionType={transactionType}
              data={product}
              itemType="products"
              idx={idx}
              disablePriceField={disablePriceField}
            />
          );
        })}
      </Scrollbar>
      <Button
        fullWidth
        variant="contained"
        color="inherit"
        size="small"
        onClick={switchHeight}
        style={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        {currentHeightEqualsHeight ? 'Expandir' : 'Colapsar'}
      </Button>
      <br />
      <br />
      {transactionType === CartTransactionEnum.SALE && (
        <Stack direction="row" justifyContent="flex-end">
          <Typography>Servicios (+)</Typography>
          <Typography style={{ width: 100, textAlign: 'right' }}>
            {formatCurrency(amountServices)}
          </Typography>
        </Stack>
      )}
      <Stack direction="row" justifyContent="flex-end">
        <Typography>Productos (+)</Typography>
        <Typography style={{ width: 100, textAlign: 'right' }}>
          {formatCurrency(amountProducts)}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="flex-end">
        <Typography>Sub total (=)</Typography>
        <Typography style={{ width: 100, textAlign: 'right' }}>
          {formatCurrency(total)}
        </Typography>
      </Stack>
    </>
  );
}
