import { ACCProduct, ACCService } from 'src/@types/cart';
import { removeItem } from 'src/redux/slices/cart';
import { useDispatch } from 'src/redux/store';

import { CostModifier } from './CostModifier';
import { PriceModifier } from './PriceModifier';

export type CartItemData = ACCProduct | ACCService;

enum CartTransactionEnum {
  EXPENSE = 'expense',
  SALE = 'sale',
}

type Props = {
  data: CartItemData;
  itemType: 'products' | 'services';
  transactionType: CartTransactionEnum;
  idx: number;
  key?: number | string;
  disablePriceField?: boolean;
};

export const CartItem = ({
  data,
  itemType,
  transactionType,
  idx,
  disablePriceField,
}: Props) => {
  const dispatch = useDispatch();

  const removeFromCart = () => {
    dispatch(
      removeItem({
        transactionType,
        itemType,
        idx,
      })
    );
  };

  return (
    <div
      style={{
        background: '#fff',
        padding: '8px 0',
        borderBottom: '1px dashed #dedede',
      }}
    >
      {transactionType === CartTransactionEnum.SALE ? (
        <>
          <PriceModifier
            product={data as ACCProduct}
            itemType={itemType}
            transactionType={transactionType}
            removeItem={removeFromCart}
            idx={idx}
            disablePriceField={disablePriceField}
          />
        </>
      ) : (
        <CostModifier
          product={data as ACCProduct}
          itemType={itemType}
          transactionType={transactionType}
          removeItem={removeFromCart}
          idx={idx}
        />
      )}
    </div>
  );
};
