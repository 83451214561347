import { Delete, Help } from '@mui/icons-material';
import {
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import { ACCProduct } from 'src/@types/cart';
import {
  getCartState,
  updatePrice,
  updateQuantity,
} from 'src/redux/slices/cart';
import { dispatch } from 'src/redux/store';
import { fCurrency } from 'src/utils/formatNumber';

import { CartTransactionEnum } from '.';
import IncrementerButton from '../custom-input/IncrementerButton';

type Props = {
  product: ACCProduct;
  idx: number;
  transactionType: CartTransactionEnum;
  itemType: 'products' | 'services';
  removeItem: VoidFunction;
  disablePriceField?: boolean;
};

export const exceptThisSymbols = ['e', 'E', '+', '-'];

export const PriceModifier = ({
  product,
  transactionType,
  itemType,
  removeItem,
  idx,
  disablePriceField,
}: Props) => {
  const { products } = useSelector(getCartState);
  const { enqueueSnackbar } = useSnackbar();
  const showInvalidStockMessage = () => {
    enqueueSnackbar('No cuentas con suficiente stock para esta venta.', {
      variant: 'error',
    });
  };

  let quantityInCart = 0;
  const productsInCart = products.filter((p) => p.id === product.id);

  if (productsInCart.length > 0) {
    quantityInCart = productsInCart
      .map((product) => product.quantity)
      .reduce((curr, next) => curr + next);
  }

  const handleNewPrice = (price: string) => {
    dispatch(
      updatePrice({
        idx,
        price,
        transactionType,
        itemType,
      })
    );
  };

  const handleQuantityChange = (quantity: number) => {
    if (quantityInCart >= product.stock) {
      showInvalidStockMessage();
      return;
    }

    dispatch(
      updateQuantity({
        idx,
        quantity,
        transactionType,
        itemType,
      })
    );
  };

  const handleQuantityIncrease = () =>
    handleQuantityChange(product.quantity + 1);

  const handleQuantityDecrease = () => {
    dispatch(
      updateQuantity({
        idx,
        quantity: product.quantity - 1,
        transactionType,
        itemType,
      })
    );
  };

  const onMaxReached = () => {
    showInvalidStockMessage();
  };

  return (
    <div style={{ marginBottom: 10 }}>
      <Stack spacing={1} marginBottom={2} direction="row" alignItems="center">
        <IconButton size="small" color="error" onClick={() => removeItem()}>
          <Delete fontSize="small" />
        </IconButton>
        <Tooltip title={product.name}>
          <Typography noWrap fontSize={18} fontWeight="bold" marginBottom={1}>
            {product.name}
          </Typography>
        </Tooltip>
      </Stack>
      <Grid container spacing={2} alignItems="center">
        {!disablePriceField && (
          <Grid item xs={12} sm={6}>
            {itemType === 'products' ? (
              <TextField
                type="number"
                label={`Precio de venta`}
                value={product.price}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Utiliza este campo para vender este producto a un precio diferente. El precio en inventario no se modificará.">
                        <IconButton>
                          <Help />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                onKeyDown={(e) =>
                  exceptThisSymbols.includes(e.key) && e.preventDefault()
                }
                onChange={(e) => handleNewPrice(e.target.value)}
              />
            ) : (
              <Typography variant="body1">
                Precio: {fCurrency(product.price)}
              </Typography>
            )}
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <IncrementerButton
            value={product.quantity}
            max={itemType === 'products' ? product.stock : undefined}
            onMaxReached={onMaxReached}
            onQtyChange={(quantity) => handleQuantityChange(quantity)}
            disabledDecrease={product.quantity <= 1}
            onIncrease={handleQuantityIncrease}
            onDecrease={handleQuantityDecrease}
          />
        </Grid>
      </Grid>
    </div>
  );
};
